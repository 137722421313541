const module = angular.module('eventix.common.directives.reservationComponents', [])
    /**
     * @ngdoc directive
     * @name eventix.common.directives:receiverMetadata
     * @param {Object} data Object containing the metadata values
     * @param {Array<MetaData>} metaData The metaData that should be asked of the receiver
     * @param {Object} [errors] An object that'll receive validation errors
     * @description
     * Render a form for a set of metaData
     * @example
     * ```
     * <receiver-metadata data="$ctrl.receiverData" meta-data="$ctrl.receiverMeta"></receiver-metadata>
     * ```
     */
    .component('receiverMetadata', {
        bindings: { data: '=', metaData: '=', errors: '=' , inputWidth: '='},
        controller: function() {
            const $ctrl = this;
            $ctrl.$postLink = function() {
                if ($ctrl.inputWidth === undefined || $ctrl.inputWidth=== null)
                    $ctrl.inputWidth = '9';
            };
        },
        template: `<metadata-collection ng-model="$ctrl.data" errors="$ctrl.errors" metadata="$ctrl.metaData">
    <form-el ng-repeat="meta in $ctrl.metaData"
             label="{{meta.type === 'boolean' ? '' : meta.translateName}}"
             errors="$ctrl.errors[meta.guid]"
             ng-attr-required="{{meta.extra | includes:'required'}}"
             no-help input-width="$ctrl.inputWidth">
        <metadata-edit ng-model="$ctrl.data[meta.guid]"
                       meta="meta"
                       track></metadata-input>
    </form-el>
</metadata-collection>`
    })
    /**
     * @ngdoc directive
     * @name eventix.common.directives:ticketReservationForm
     * @param {TicketReservation} reservation An instance of TicketReservation
     * @description
     * Render a form for a ticket reservation. It'll display all the metadata and offer choices if there are
     * (semi-)optional products. Refreshes on `TicketReservationChanged` events. Will delegate rendering to
     * `groupReservationForm`
     * @example
     * ```
     * <ticket-reservation-form reservation="$ctrl.reservation"></ticket-reservation-form>
     * ```
     */
    .component('ticketReservationForm', {
        template: '<group-reservation-form ng-repeat="group in $ctrl.reservation.groups" group="group" reservation="$ctrl.reservation"></group-reservation-form>',
        bindings: { reservation: '<' }
    })
    /**
     * @ngdoc directive
     * @name eventix.common.directives:groupReservationForm
     * @param {TicketReservation} reservation An instance of TicketReservation
     * @param {ProductGroup} group An instance of ProductGroup
     * @description
     * Render a form for a product group. It'll display all the metadata and offer choices if there are
     * (semi-)optional products. Refreshes on `TicketReservationChanged` events. Delegates product choices
     * to `productSelection` component.
     * @example
     * ```
     * <group-reservation-form reservation="$ctrl.reservation" group="$ctrl.reservation.groups[0]"></group-reservation-form>
     * ```
     */
    .component('groupReservationForm', {
        template: `<div ng-repeat="reservation in $ctrl.productReservations">
    <!-- product specific metadata per reservation -->
    <metadata-collection ng-model="$ctrl.productValues[reservation.guid]"
                         class="row"
                         metadata="$ctrl.productMetaData[reservation.productId]"
                         ng-if="$ctrl.productMetaData[reservation.productId].length"
                         errors="$ctrl.reservation.$errors">
        <form-el ng-repeat="meta in $ctrl.productMetaData[reservation.productId]"
                 errors="$ctrl.reservation.$errors[meta.guid]"
                 ng-attr-required="{{meta.extra | includes:'required'}}"
                 label="{{$ctrl.getLabel(meta, reservation.productId, $parent.$index)}}"
                 input-width="9" no-help>
            <metadata-edit ng-model="$ctrl.productValues[reservation.guid][meta.guid]"
                           ng-change="$ctrl.notify(meta, reservation)"
                           meta="meta"
                           track></metadata-input>
        </form-el>
    </metadata-collection>
</div>
<product-selection group="$ctrl.group" reservation="$ctrl.reservation" products="$ctrl.products" ng-hide="$ctrl.group.required"></product-selection>
<!-- person specific metadata for group -->
<metadata-collection ng-model="$ctrl.personValues"
                     class="row"
                     metadata="$ctrl.personMetaData"
                     errors="$ctrl.reservation.$errors">
    <form-el ng-repeat="meta in $ctrl.personMetaData"
             errors="$ctrl.reservation.$errors[meta.guid]"
             label="{{meta.type === 'boolean' ? '' : meta.translateName}}"
             ng-attr-required="{{meta.extra | includes:'required'}}"
             input-width="9" no-help>
        <metadata-edit ng-model="$ctrl.personValues[meta.guid]"
                       ng-change="$ctrl.notify(meta)"
                       meta="meta"
                       track></metadata-input>
    </form-el>
</metadata-collection>
<hr class="mv10" ng-hide="$ctrl.group.required && !$ctrl.hasMetaData">`,
        bindings: { group: '<', reservation: '<' },
        controller: function($scope, $translate, Ticket, $filter) {
            const $ctrl = this;
            $ctrl.$postLink = function() {
                $ctrl.pool = $ctrl.reservation.productReservations;
                $ctrl.unique = $ctrl.group.uniqueness === 1;
                $ctrl.products = _.fromPairs(
                    $ctrl.group.products.map(guid => [ guid, _.find($ctrl.reservation.products, { guid }) ])
                );

                // Sort Products by Root Ticket
                let sortedProducts = [];
                Ticket.cached[$ctrl.reservation.ticket_guid].$queryProduct(true).then(allProducts => {
                    let sortedAllProducts = $filter('sortByPredecessor')(allProducts);
                    let originalProducts = $ctrl.products;
                    _.forEach(sortedAllProducts, function (p){
                        if(p.guid in originalProducts)
                            sortedProducts.push(p);
                    })
                    $ctrl.products = _.keyBy(sortedProducts, 'guid');
                });

                findProductReservations();
                $scope.$on('TicketReservationChanged', (ev, reservation) => {
                    if($ctrl.reservation !== reservation)
                        return;
                    findProductReservations();
                });
            };

            /**
             * Format a metaData label
             * @param {MetaData} meta MetaData instance
             * @param {String} productId Product GUID
             * @param {Number} index Index of the reservation for product guid
             * @returns {String} A translated label
             */
            $ctrl.getLabel = function(meta, productId, index) {
                let productName = $ctrl.products[productId].name;
                let label = `${index + 1}. ${productName}:`;
                if(meta.type === 'boolean')
                    return label;
                label += $translate.instant(meta.translateName);
                return label;
            };

            /**
             * Bubble changes upward to the reservation pool
             * @param {MetaData} metaData The metaData whos value changed
             * @param {Object} [productReservation] The specific reservation whose metaData was changed, only required for applies_to:product metaData
             */
            $ctrl.notify = function notify(metaData, productReservation) {
                if(productReservation) {
                    $ctrl.pool.setMetaDataValue(
                        metaData.guid,
                        $ctrl.productValues[productReservation.guid][metaData.guid],
                        productReservation.productId,
                        productReservation.guid
                    );
                } else {
                    $ctrl.pool.setMetaDataValue(
                        metaData.guid,
                        $ctrl.personValues[metaData.guid],
                        $ctrl.group.products
                    );
                }
            };

            /**
             * Update internal state of product reservations and metaData that should be asked
             */
            function findProductReservations() {
                $ctrl.productReservations = [];
                for(let productId of $ctrl.group.products)
                    $ctrl.productReservations.push(...$ctrl.pool.filter(productId));

                // Sync person metadata (values)
                $ctrl.personMetaData = $ctrl.pool.getPersonMetaDataFor(...$ctrl.group.products);
                $ctrl.personValues = $ctrl.pool.getPersonMetaDataValuesFor(...$ctrl.group.products);

                // Sync product metadata (values)
                $ctrl.productMetaData = {};
                $ctrl.productValues = {};
                for(let productId of $ctrl.group.products)
                    $ctrl.productMetaData[productId] = $ctrl.pool.getProductMetaDataFor(productId);
                for(let reservation of $ctrl.productReservations) {
                    let values = $ctrl.pool.getProductMetaDataValuesFor(reservation.productId, reservation.guid);
                    $ctrl.productValues[reservation.guid] = values;
                }
                $ctrl.hasMetaData = $ctrl.personMetaData.length > 0 || _.some($ctrl.productMetaData, m => m.length);
            }
        }
    })
    /**
     * @ngdoc directive
     * @name eventix.common.directives:productReservationForm
     * @param {productReservationForm} reservation An instance of productReservationForm
     * @param {productReservationForm} group An instance of productReservationForm
     * @description
     * Render a form for a shoptionals / products. It'll display all the metadata and offer choices if there are
     * (semi-)optional products.
     * @example
     * ```
     * <product-reservation-form ng-repeat="product in vm.globalProducts" product="product" order="vm.order"></product-reservation-form>
     * ```
     */
    .component('productReservationForm', {
        template: `<div class="{{ $ctrl.className }} global-product global-product-{{ $ctrl.product.guid }} {{ $ctrl.product.isContainerProduct ? 'global-product-is-container' : '' }}">
    <div ng-switch="$ctrl.outputFormat">
        <div ng-switch-when="sail">
            <div class="md_show-list-r">
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel">
                        <div class="panel-heading" role="tab" id="DeparListOne">
                            <h4 class="panel-title">
                                <a class="md_collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#DeparCollapseOne" aria-expanded="true" aria-controls="DeparCollapseOne" | translate>common.shop.refund_protect_title</a>
                            </h4>
                        </div>
                        <div id="DeparCollapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="DeparListOne">
                            <div class="panel-body md_departure">
                                <form class="md_form">
                                    <label class="custom-check mb-3"><span translate translate-values="{value:$ctrl.formattedPrice, terms:$ctrl.termsUrl, costs:$ctrl.formattedCosts}">common.shop.refund_protect_body</span> 
                                        <input ng-disabled="$ctrl.busy" id="{{ $ctrl.product.guid }}" type="checkbox" ng-model="$ctrl.checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ng-switch-default>
            <uib-accordion close-others="false" ng-if="$ctrl.product.guid == $ctrl.refundProtectProductGuid">
                <div class="event-group">
                    <div class="panel-default" uib-accordion-group is-open="true">
                        <uib-accordion-heading><span translate>common.shop.refund_protect_title</span> <span class="ticket-price">{{ $ctrl.product.displayGlobalPrice($ctrl.order) | formatCurrency }}</span></uib-accordion-heading>
                        <label class="custom-check mb-3" for="{{ $ctrl.product.guid }}"><input ng-disabled="$ctrl.busy" id="{{ $ctrl.product.guid }}" type="checkbox" ng-model="$ctrl.checked"> <span translate translate-values="{value:$ctrl.formattedPrice, terms:$ctrl.termsUrl, costs:$ctrl.formattedCosts}">common.shop.refund_protect_body</span> .
                        </label>
                    </div>
                </div>
            </uib-accordion>
            <uib-accordion close-others="false" ng-if="$ctrl.product.guid != $ctrl.refundProtectProductGuid">
                <div class="event-group">
                    <div class="panel-default" uib-accordion-group is-open="true">
                        <uib-accordion-heading><span translate>{{ $ctrl.product.name }}</span> <span class="ticket-price">{{ $ctrl.product.displayGlobalPrice($ctrl.order) | formatCurrency }}</span></uib-accordion-heading>
                        <label class="custom-check mb-3" for="{{ $ctrl.product.guid }}"><input ng-disabled="$ctrl.busy" id="{{ $ctrl.product.guid }}" type="checkbox" ng-model="$ctrl.checked"> <span translate translate-values="{value:$ctrl.formattedPrice, terms:$ctrl.termsUrl, costs:$ctrl.formattedCosts}">{{$ctrl.product.description}}</span>.
                        </label>
                    </div>
                </div>
            </uib-accordion>
        </div>
    </div>
<!--    <div class="col-xs-12">-->
<!--        <div ng-repeat="reservation in $ctrl.order.globalProductReservations">-->
<!--            <metadata-collection ng-model="$ctrl.order.globalProductsProductValues[reservation.reservation]"-->
<!--                                 class="row"-->
<!--                                 metadata="$ctrl.order.globalProductMetadatas[reservation.product_guid]"-->
<!--                                 ng-if="$ctrl.order.globalProductMetadatas[reservation.product_guid].length"-->
<!--                                 errors="$ctrl.productErrors[reservation.reservation]">-->
<!--                <form-el ng-repeat="meta in $ctrl.order.globalProductMetadatas[reservation.product_guid]"-->
<!--                         errors="$ctrl.order.globalProductErrors[reservation.reservation][meta.guid]"-->
<!--                         ng-attr-required="{{meta.extra | includes:'required'}}"-->
<!--                         label="{{$ctrl.getLabel(meta, reservation.product_guid, $parent.$index)}}"-->
<!--                         input-width="9" no-help>-->
<!--                    <metadata-edit ng-model="$ctrl.order.globalProductsProductValues[reservation.reservation][meta.guid]"-->
<!--                                   ng-change="$ctrl.notify(meta, reservation)"-->
<!--                                   meta="meta"-->
<!--                                   track></metadata-edit>-->
<!--                </form-el>-->
<!--            </metadata-collection>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="col-xs-12">-->
<!--        <metadata-collection ng-model="$ctrl.order.globalProductsPersonValues"-->
<!--                             class="row"-->
<!--                             metadata="$ctrl.order.globalPersonMetadatas"-->
<!--                             errors="$ctrl.order.globalPersonErrors">-->
<!--            <form-el ng-repeat="meta in $ctrl.order.globalPersonMetadatas"-->
<!--                     errors="$ctrl.order.globalPersonErrors[meta.guid]"-->
<!--                     label="{{meta.type === 'boolean' ? '' : meta.translateName}}"-->
<!--                     ng-attr-required="{{meta.extra | includes:'required'}}"-->
<!--                     input-width="9" no-help>-->
<!--                <metadata-edit ng-model="$ctrl.order.globalProductsPersonValues[meta.guid]"-->
<!--                               ng-change="$ctrl.notify(meta)"-->
<!--                               meta="meta"-->
<!--                               track></metadata-edit>-->
<!--            </form-el>-->
<!--        </metadata-collection>-->
<!--    </div>-->
<!--    </div>-->
</div>`,
        bindings: { product: '<', order: '<', className: '<', outputFormat: '<'},
        controller: function($http, $scope, $rootScope, GlobalProductReservation, $q, syncHttp, $filter, Product, $translate, Currencies) {
            const $ctrl = this

            $ctrl.busy = false
            $ctrl.displayPice = 0;

            $ctrl.reserve = reserve

            $ctrl.release = release

            $ctrl.isNowChecked = false;
            // Has reservation?
            $ctrl.isInit = true;

            $ctrl.$postLink = () => {
                $ctrl.shop = $ctrl.order.shop;
                $ctrl.termsUrl = REFUND_PROTECT_TERMS;
                $ctrl.refundProtectProductGuid = REFUND_PROTECT_GUID;
                $ctrl.isNowChecked = ($ctrl.order.globalProductReservations.filter(g => g.product_guid === $ctrl.product.guid).length > 0) ? true : false;
                $ctrl.checked = $ctrl.isNowChecked;
                $ctrl.formattedPrice = $filter('formatCurrency')($ctrl.product.displayGlobalPrice($ctrl.order), Currencies.selected);
                $ctrl.formattedCosts = '';
                let percentage = Number(Math.round($ctrl.product.service_cost_percentage * 100) + 'e-0');
                if($ctrl.product.service_cost_percentage != 0 && $ctrl.product.service_cost  == 0){
                    $ctrl.formattedCosts = percentage + '%';
                }
                else if($ctrl.product.service_cost_percentage ==  0 && $ctrl.product.service_cost != 0){
                    $ctrl.formattedCosts = $filter('formatCurrency')($ctrl.product.service_cost, Currencies.selected);
                }
                else if($ctrl.product.service_cost_percentage != 0 && $ctrl.product.service_cost  != 0){
                    $ctrl.formattedCosts =  percentage +'% + ' + $filter('formatCurrency')($ctrl.product.service_cost, Currencies.selected);
                }
            };

            $scope.$watch('$ctrl.checked', toggleChecked);

            function toggleChecked(){
                if($ctrl.isInit) {
                    $ctrl.isInit = false;
                    return;
                }

                if(!$ctrl.isNowChecked)
                    $ctrl.reserve();
                else
                    $ctrl.release();
                $ctrl.isNowChecked = $ctrl.checked;
            }

            /**
             * Reserve a global product
             */
            function reserve() {
                let productId = $ctrl.product.guid
                return $http.get(`/${$ctrl.shop.guid}/reserve/product/${productId}`)
                    .then((response) => {
                        response.data = Object.assign(response.data, {
                            product_guid: productId
                        })

                        let productReservation =  new GlobalProductReservation(response.data, $ctrl.shop)
                        $ctrl.order.globalProductReservations.push(productReservation)
                        $rootScope.$broadcast('GlobalProductReservationsChanged')
                        // updateMetaData()
                        // ticket.constructor.broadcastAPIMessage(response)
                        return response.data.reservation
                    }, error => {
                        // ticket.constructor.broadcastAPIMessage(error);
                        return $q.reject(error);
                    })
            }

            /**
             * Release a global product reservation
             * @param {boolean} sync whether or not to call this function synchronized
             */
            function release(sync) {
                const productId = $ctrl.product.guid
                let globalProductReservationsForProduct = $ctrl.order.globalProductReservations.filter(g => g.product_guid === productId)

                $ctrl.busy = true

                if (globalProductReservationsForProduct.length > 0) {
                    const guid = globalProductReservationsForProduct[[globalProductReservationsForProduct.length-1]].reservation
                    const url = `/${$ctrl.shop.guid}/reserve/product/${productId}/${guid}`
                    let promise = null

                    if (sync) {
                        promise = syncHttp('DELETE', url)
                    }
                    else {
                        promise = $http.delete(url)
                    }

                    return promise
                        .then(response => {
                            $ctrl.order.globalProductReservations = $ctrl.order.globalProductReservations.filter(g => g.reservation !== guid)
                            $rootScope.$broadcast('GlobalProductReservationsChanged')
                            $ctrl.busy = false
                            // updateMetaData()
                            return response.data
                        }, error => {
                            $ctrl.busy = false

                            return $q.reject(error)
                        })
                }
                else {
                    return new Promise((resolve, reject) => {
                        $ctrl.busy = false

                        reject('No global products reserved with this product guid.')
                    })
                }
            }

            $ctrl.getLabel = function(meta, productId, index) {
                let product = Product.cached[productId]
                let productName = product.name;
                let label = `${index + 1}. ${productName}: `;
                if(meta.type === 'boolean')
                    return label;
                label += $translate.instant(meta.translateName);
                return label;
            };
        }
    })
    /**
     * @ngdoc directive
     * @name eventix.common.directives:productSelection
     * @param {TicketReservation} reservation An instance of TicketReservation
     * @param {ProductGroup} group An instance of ProductGroup
     * @param {Array<Product>} products An array of products belonging to the group
     * @description
     * It renders the selection of products.
     *
     * Will automatically determine which product selection interface to show.
     *
     * 1. Non-unique groups will default to plus/minus buttons because multiple products of the same type must be
     * selectable.
     * 2. Unique groups with min(1) and max(1) will show either select or radios because they don't suggest the
     * user the affordance to deselect the product entirely.
     * 3. Other unique groups will show either a multi-select or checkboxes.
     * 4. The switch from checkbox/radio to (multi)select is made when there are >3 products. Radios and
     * checkboxes offer more information at first glance, with fewer clicks, but at the cost of more real-estate.
     * @example
     * ```
     * <product-selection reservation="$ctrl.reservation" group="$ctrl.reservation.groups[0]" products="$ctrl.products"></product-selection>
     * ```
     */
    .component('productSelection', {
        template: `<p ng-show="$ctrl.group.description.length">
    {{ $ctrl.group.description }} <small ng-show="::$ctrl.getExplanation().length">&mdash; {{ ::$ctrl.getExplanation() | translate:$ctrl.group }}</small>
</p>
<div ng-switch="$ctrl.type">
    <div ng-switch-when="optional">
        <div class="row" ng-repeat="product in $ctrl.products">
            <div class="col-xs-12 col-sm-6 pv5">
               <custom-translate>{{ product.name }}</custom-translate> <small ng-show="::product.description.length">&mdash; {{ ::product.description }}</small>
            </div>
            <div class="col-xs-6 col-sm-3 pv5 text-center">
                <a class="btn btn-xs btn-minplus btn-primary"
                   ng-disabled="$ctrl.busy || $ctrl.amounts[product.guid] === 0  || $ctrl.total <= $ctrl.group.min_bound"
                   ng-click="$ctrl.release(product.guid)">
                    <i class="fa fa-minus"></i>
                </a>
                {{ $ctrl.amounts[product.guid] }}
                <a class="btn btn-xs btn-minplus btn-primary"
                   ng-click="$ctrl.reserve(product.guid)"
                   ng-disabled="$ctrl.busy || ($ctrl.group.uniqueness || 100) <= $ctrl.amounts[product.guid] || ($ctrl.total >= ($ctrl.group.max_bound || 100))">
                    <i class="fa fa-plus"></i>
                </a>
            </div>
            <div class="col-xs-6 col-sm-3 pv5 text-right">
                {{ $ctrl.amounts[product.guid] }}
                &times; {{ product.ticketPrice | formatCurrency }}
            </div>
        </div>
    </div>
    <div ng-switch-when="checkboxes">
        <div class="row" ng-repeat="product in $ctrl.products">
            <div class="col-xs-9 checkbox-{{ ::product.guid}}">
                <input ng-disabled="$ctrl.busy" id="{{ $ctrl.getLabel('checkbox', product.guid, $index) }}" ng-change="$ctrl.toggle(product.guid)" type="checkbox" ng-model="$ctrl.checked[product.guid]">
                <label for="{{ $ctrl.getLabel('checkbox', product.guid, $index) }}" class="inline">
                  <custom-translate>{{ product.name }}</custom-translate>
                    <small ng-show="::product.description.length">&mdash; {{ product.description }}</small>
                </label>
            </div>
            <div class="col-xs-3 text-right">{{ product.ticketPrice | formatCurrency }}</div>
        </div>
    </div>
    <div ng-switch-when="radios">
        <div class="row" ng-repeat="product in $ctrl.products">
            <div class="col-xs-9 radio-{{ ::product.guid}}">
                <input ng-disabled="$ctrl.busy" id="{{ $ctrl.getLabel('radio', product.guid, $index) }}" ng-change="$ctrl.toggle(product.guid)" type="radio" ng-model="$ctrl.radio" value="{{product.guid}}">
                <label for="{{ $ctrl.getLabel('radio', product.guid, $index) }}" class="inline">
                   <custom-translate>{{ product.name }}</custom-translate>
                    <small ng-show="::product.description.length">&mdash; {{ product.description }}</small>
                </label>
            </div>
            <div class="col-xs-3 text-right">{{ product.ticketPrice | formatCurrency }}</div>
        </div>
    </div>
    <div ng-switch-when="select" style="width: 75%">
        <tx-select btn-class="form-control"
                    ng-disabled="$ctrl.busy"
                    sublabel="description"
                    ng-model="$ctrl.reservedGuid"
                    tx-options="product.guid as product.nameWithPrice for product in $ctrl.products"
                    before-select="$ctrl.toggle(product.guid)"></tx-select>
    </div>
    <div ng-switch-when="multiselect" style="width: 75%">
        <tx-select btn-class="form-control"
                    ng-disabled="$ctrl.busy"
                    ng-model="$ctrl.reservedGuids"
                    sublabel="description"
                    no-toggle-all
                    multiple
                    tx-options="product.guid as product.nameWithPrice for product in $ctrl.products"
                    before-select="$ctrl.toggle(product.guid)"
                    before-deselect="$ctrl.toggle(product.guid)"></tx-select>
    </div>
</div>`,
        bindings: { reservation: '<', group: '<', products: '<' },
        controller: function($scope, $q, UIMessages) {
            const $ctrl = this;
            $ctrl.busy = false;
            $ctrl.updateAmounts = updateAmounts;
            $ctrl.toggle = toggle;
            $ctrl.reserve = reserve;
            $ctrl.release = release;
            $ctrl.getExplanation = getExplanation;

            $ctrl.$postLink = function() {
                $ctrl.pool = $ctrl.reservation.productReservations;
                $ctrl.unique = $ctrl.group.uniqueness === 1;
                $scope.$on('TicketReservationChanged', (ev, tr) => {
                    if(tr === $ctrl.reservation)
                        updateAmounts();
                });
                determineSelectionType();
                $scope.$watch('$ctrl.products', updateAmounts);
            };

            function getExplanation() {
                const min = $ctrl.group.min_bound,
                    max = $ctrl.group.max_bound,
                    unique = $ctrl.group.uniqueness;
                if(min && max && unique > 1)
                    return 'models.productGroup.shopLabel.minMaxNonUnique';
                else if(max && unique > 1)
                    return 'models.productGroup.shopLabel.maxNonUnique';
                else if(min && unique > 1)
                    return 'models.productGroup.shopLabel.minNonUnique';
                else if(unique > 1)
                    return 'models.productGroup.shopLabel.nonUnique';
                else if(min && min === max)
                    return 'models.productGroup.shopLabel.exact';
                else if(max && min)
                    return 'models.productGroup.shopLabel.minMax';
                else if(min)
                    return 'models.productGroup.shopLabel.min';
                else if(max)
                    return 'models.productGroup.shopLabel.max';
                return '';
            }

            /**
             * Toggle a product reservation
             *
             * UI function for product selection in groups with `uniqueness=1`. Will release existing
             * reservation for select or radio types.
             *
             * Checks and enforces the group boundaries.
             * @param {String} productId Product to toggle
             * @return {Promise} Resolves when done
             */
            function toggle(productId) {
                if(/^(select|radios)$/.test($ctrl.type)) {
                    let promise = $q.resolve();
                    const currentProductId = _.findKey($ctrl.amounts, i => i > 0);
                    if(currentProductId === productId)
                        return promise;
                    return $ctrl.pool.reserve(productId).then(() => {
                        if(currentProductId)
                            return $ctrl.pool.release(currentProductId);
                        return $q.resolve();
                    });
                }
                const isSelected = $ctrl.amounts[productId] > 0;
                if(isSelected)
                    return release(productId).then(updateAmounts, updateAmounts);
                return reserve(productId).then(updateAmounts, updateAmounts);
            }

            /**
             * Format a metaData label
             * @param {MetaData} meta MetaData instance
             * @param {String} productId Product GUID
             * @param {Number} index Index of the reservation for product guid
             * @returns {String} A translated label
             */
            $ctrl.getLabel = function(type, productId, index) {
                return $ctrl.pool.getTicketReservation().reservation
                    + '-' + type + '-' + productId + '-' + index;
            };

            /**
             * Reserve a product
             *
             * Checks and enforces the group boundaries.
             * @param {String} productId Product to reserve
             * @returns {Promise} Resolves when done
             */
            function reserve(productId) {
                if($ctrl.busy)
                    return $q.reject(new Error('Still working...'));
                const productTotal = $ctrl.amounts[productId];
                if(productTotal >= $ctrl.group.uniqueness && $ctrl.group.uniqueness > 0) {
                    UIMessages.push({ message: 'models.productGroup.notice.maxSelectedForProduct', translateValues: $ctrl.group });
                    return $q.reject(new Error('max_selected_for_product'));
                } else if($ctrl.total >= $ctrl.group.max_bound && $ctrl.group.max_bound > 0) {
                    UIMessages.push({ message: 'models.productGroup.notice.maxSelected', translateValues: $ctrl.group });
                    return $q.reject(new Error('max_selected'));
                }
                $ctrl.busy = true;
                return $ctrl.pool.reserve(productId).then(response => {
                    $ctrl.busy = false;
                    return $q.resolve(response);
                }, error => {
                    $ctrl.busy = false;
                    return $q.reject(error);
                });
            }

            /**
             * Release a product
             *
             * Checks and enforces the group boundaries.
             * @param {String} productId Product to release
             * @returns {Promise} Resolves when done
             */
            function release(productId) {
                if($ctrl.busy)
                    return $q.reject(new Error('Still working...'));
                $ctrl.busy = true;
                return $ctrl.pool.release(productId).then(response => {
                    $ctrl.busy = false;
                    return $q.resolve(response);
                }, error => {
                    $ctrl.busy = false;
                    return $q.reject(error);
                });
            }

            /**
             * Determine which product selection interface to show.
             */
            function determineSelectionType() {
                if($ctrl.group.isRequiredGroup())
                    $ctrl.type = 'required';
                else if($ctrl.unique) {
                    if($ctrl.group.max_bound === 1 && $ctrl.group.min_bound === 1)
                        $ctrl.type = $ctrl.group.products.length > 3 ? 'select' : 'radios';
                    else
                        $ctrl.type = $ctrl.group.products.length > 3 ? 'multiselect' : 'checkboxes';
                } else
                    $ctrl.type = 'optional';
            }

            /**
             * Update the product reservation amounts when the ticket reservation changes
             */
            function updateAmounts() {
                $ctrl.amounts = {};
                _.forEach($ctrl.products, product => {
                    $ctrl.amounts[product.guid] = $ctrl.pool.filter(product.guid).length;
                });
                $ctrl.total = _.sum(_.values($ctrl.amounts));
                if($ctrl.unique) {
                    $ctrl.checked = _.mapValues($ctrl.amounts, i => i > 0);
                    $ctrl.reservedGuids = _.keys($ctrl.checked).filter(id => $ctrl.checked[id]);
                    if($ctrl.type === 'radios')
                        $ctrl.radio = _.first($ctrl.reservedGuids);
                    if($ctrl.type === 'select')
                        $ctrl.reservedGuid = _.first($ctrl.reservedGuids);
                }
            }


        }
    })
    .component('ticketReservationSummary', {
        template: `<strong>{{ ::$ctrl.ticket.name }}</strong>
        <ul class="list-disc">
    <li ng-repeat="meta in $ctrl.personMetaData">
        {{ ::meta.translateName | translate }}: {{ ::$ctrl.personValues[meta.guid] }}
    </li>
</ul>
<div ng-repeat="reservation in $ctrl.productReservations">
    <strong><custom-translate>{{ ::$ctrl.products[reservation.productId].name }}</custom-translate></strong>
    <exchange-currency locale="$ctrl.locales.selected"
                       event="$ctrl.event"
                       amount="$ctrl.products[reservation.productId].ticketPrice"
                       convert-to="$ctrl.currencies.selected"
                       convert-from="$ctrl.shop.currency"
                       class="product-price">
    </exchange-currency>
    <ul>
        <li ng-repeat="meta in $ctrl.productMetaData[reservation.productId]">
            {{ ::meta.translateName | translate }}: {{ ::$ctrl.productValues[reservation.productId][meta.guid] }}
        </li>
    </ul>
</div>
<div>
    <span class="product-price">
            <span ng-show="$ctrl.ticket.service_cost && $ctrl.reservation.price"
                  translate="models.ticket.service_cost"
                  translate-values="{ service_costs: $ctrl.serviceCosts }">
            </span>
    </span>
</div>`,
        bindings: { reservation: '<' },
        controller: function(Locales, Currencies, OrderManager, $filter, $scope) {
            const $ctrl = this;
            $ctrl.$postLink = function() {
                $ctrl.shop = OrderManager.instance.shop;
                $ctrl.currencies = Currencies;
                $ctrl.locales = Locales;
                $ctrl.event = OrderManager.instance.events[$ctrl.reservation.event_guid];
                $ctrl.pool = $ctrl.reservation.productReservations;
                $ctrl.ticket = $ctrl.reservation.ticket;
                $ctrl.products = _.keyBy($ctrl.reservation.products, 'guid');
                $ctrl.productIds = _.map($ctrl.reservation.products, 'guid');
                $ctrl.serviceCosts = $filter('formatCurrency')(
                    ($ctrl.ticket.service_cost + $ctrl.ticket.original_price * $ctrl.ticket.service_cost_percentage),
                    Currencies.selected,
                    $ctrl.shop.currency,
                    Locales.selected
                );
                findProductReservations();
                $scope.$on('TicketReservationChanged', (ev, reservation) => {
                    if($ctrl.reservation !== reservation)
                        return;
                    findProductReservations();
                });
            };

            /**
             * Update internal state of product reservations and metaData that should be asked
             */
            function findProductReservations() {
                $ctrl.productReservations = [];
                for(let productId of $ctrl.productIds)
                    $ctrl.productReservations.push(...$ctrl.pool.filter(productId));

                // Sync person metadata (values)
                $ctrl.personMetaData = $ctrl.pool.getPersonMetaDataFor(...$ctrl.productIds);
                $ctrl.personValues = $ctrl.pool.getPersonMetaDataValuesFor(...$ctrl.productIds);

                // Sync product metadata (values)
                $ctrl.productMetaData = {};
                $ctrl.productValues = {};
                for(let productId of $ctrl.productIds)
                    $ctrl.productMetaData[productId] = $ctrl.pool.getProductMetaDataFor(productId);
                for(let reservation of $ctrl.productReservations) {
                    let values = $ctrl.pool.getProductMetaDataValuesFor(reservation.productId, reservation.guid);
                    $ctrl.productValues[reservation.guid] = values;
                }
                $ctrl.hasMetaData = $ctrl.personMetaData.length > 0 || _.some($ctrl.productMetaData, m => m.length);
            }
        }
    });

export default module.name;
