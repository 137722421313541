import template from './tickets.html';
import style from './tickets.less';
import seatsTemplate from './seats/seats.html';

export default angular.module('eventix.shop.tickets', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.tickets', {
            url: '/tickets?eventId',
            views: {
                'shop': {
                    controller: 'TicketsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('TicketsController', function(UIMessages, shop, order, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
        $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };
        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.tickets = order.tickets;
        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;
        vm.currentEvent;

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        let today = moment();
        let minStart = moment("2020-08-12");
        let minDate;
        if(today.isAfter(minStart)){
            minDate = today;
        }
        else{
            minDate = minStart;
        }

        let currentDate;
        if(vm.eventFilter){
            let currentEvent = vm.events[vm.eventFilter];
            currentDate = getDateFromCurrentEvent(currentEvent.name);
        }
        else{
            currentDate = minDate;
        }

        if(!vm.eventFilter){
            updateStateToEvent();
        }

        vm.updateEvent = updateStateToEvent;

        function updateStateToEvent() {
            let CurrentSearch = moment(currentDate).locale('en').format('YYYY-MM-DD');
            _.each(vm.events, function(e){
                let eventName = e.name;
                let eventDate = getDateFromCurrentEvent(eventName)
                if(eventDate == CurrentSearch){
                    vm.noTickets = false;
                    $state.go('.', {eventId: e.guid });
                    return;
                }
                vm.noTickets = true;
            });
        }

        vm.setCurrentEvent = function(eventId){
            $state.go('.', {eventId: eventId});
        }

        function getDateFromCurrentEvent(currentEventName){
            let split = currentEventName.split("-");
            let dateSplits = split[1].split(" ");
            let currentEventDate = dateSplits[1] + "-" + dateSplits[2] + "-" + dateSplits[3] + " 2020";
            return moment(currentEventDate).format('YYYY-MM-DD');
        }

        // if(vm.fourStepWizard && !vm.eventFilter)
        //     $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        vm.setTickets = function() {
            vm.prefix = '';
            if(vm.eventFilter){
                let currentEvent = vm.currentEvent = vm.events[vm.eventFilter];
                currentDate = getDateFromCurrentEvent(currentEvent.name);
                let split = currentEvent.name.split('-');
                vm.prefix = split[0].trim();

            }

            vm.currentTickets = {};
            vm.currentTicketGuids = [];

            vm.currentEvents = _.filter(_.map(vm.events, function(e) {
                let splits = e.name.split('-')[1];
                let daySplits = splits.split(' ');
                e.dayName = daySplits[1];
                e.dayNumber = daySplits[2];
                e.dayMonth = daySplits[3];
                if (e.name.startsWith(vm.prefix)) return e;
                return null;
            }));

            vm.currentEventGuids = vm.currentEvents.map(e => e.guid);
            _.each(vm.tickets, function(tickets,eventId) {
                if(_.indexOf(vm.currentEventGuids, eventId) !== -1){
                    if(!vm.eventFilter) {
                        vm.currentTickets[eventId] = tickets;
                        vm.currentTicketGuids = vm.currentTicketGuids.concat(tickets.map(t => t.guid));
                    }
                    else if (vm.eventFilter === eventId){
                        vm.currentTickets[eventId] = tickets;
                        vm.currentTicketGuids = vm.currentTicketGuids.concat(tickets.map(t => t.guid));
                    }
                }
            });
            let firstCollape = true;
            _.each(vm.collapses, function(c){
                c.custom_is_open = false;
                if(firstCollape) {
                    var presents = _.intersection(c.ticket_guids, vm.currentTicketGuids);
                    if (presents.length > 0) {
                        c.custom_is_open = true;
                        firstCollape = false;
                    }
                }
            });
        };
        $scope.$watch('vm.eventFilter', vm.setTickets, true);


        vm.setCurrentEvent = function(eventId){
            $state.go('.', {eventId: eventId});
        }

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.openSeatSelection = function(ticket, eventId) {
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            if (_.flatten(_.values(order.reservations)).length < 1)
                UIMessages.push('common.shop.order.empty');
            else
                $state.go('eventix.shop.ticketOptions', { shopId: shop.guid , eventId: $state.params.eventId});
        };

        vm.reserve = function(ticketGuid, eventId){
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.toId = function(string){
            return string.replace(/\W/g, '');
        };


    }).name;
